exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-auto-aprendizaje-tsx": () => import("./../../../src/pages/blog/auto-aprendizaje.tsx" /* webpackChunkName: "component---src-pages-blog-auto-aprendizaje-tsx" */),
  "component---src-pages-blog-back-end-tsx": () => import("./../../../src/pages/blog/back-end.tsx" /* webpackChunkName: "component---src-pages-blog-back-end-tsx" */),
  "component---src-pages-blog-desarrollo-web-tsx": () => import("./../../../src/pages/blog/desarrollo-web.tsx" /* webpackChunkName: "component---src-pages-blog-desarrollo-web-tsx" */),
  "component---src-pages-blog-front-end-tsx": () => import("./../../../src/pages/blog/front-end.tsx" /* webpackChunkName: "component---src-pages-blog-front-end-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-posts-data-aprender-desarrollo-web-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/posts/data/aprender-desarrollo-web.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-posts-data-aprender-desarrollo-web-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-posts-data-bienvenidos-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/posts/data/bienvenidos.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-posts-data-bienvenidos-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-posts-data-quiero-ser-autodidacta-y-triunfar-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/posts/data/quiero-ser-autodidacta-y-triunfar.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-posts-data-quiero-ser-autodidacta-y-triunfar-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

